const Auctions = () => import('./views/Auctions.vue')
const Listings = () => import("./views/Listings.vue");
const ListingView = () => import("./views/ListingView.vue");
const ListingEdit = () => import("./views/ListingEdit.vue");
const ListingNew = () => import("./views/ListingNew.vue");
const ListingsOwn= () => import( "./views/ListingsOwn.vue");
const QuestionsAsked = () => import("./views/QuestionsAsked.vue");
const QuestionsReceived = () => import("./views/QuestionsReceived.vue");
const Cart = () => import("./views/Cart.vue");
const ListingCrop = () => import("./components/ListingCrop.vue");
const Sales = () => import('./views/Sales.vue')
const Purchases = () => import('./views/Purchases.vue');

const SalesDetail = () => import('./views/SalesDetail.vue')
const PurchasesDetail = () => import('./views/PurchasesDetail.vue')

const Settings = () => import('./views/Settings.vue')

import LimeHome from "./views/LimeHome.vue";

let questionsMenu = {
  title: "Preguntas",
  id: "lime-questions",
  icon: "fas fa-question",
  menu: [
    {
      title: "Recibidas",
      route: "questions-received"
    },
    {
      title: "Hechas",
      route: "questions-asked"
    }
  ]
};

var transactionsMenu = {
  title: "Operaciones",
  id: "transactions",
  icon: "fas fa-question",
  menu: [
    {
      title: "Compras",
      route: "purchases"
    }, {
      title: "Ventas",
      route: "sales"
    }, {
      title: "Subastas",
      route: "auctions"
    }
  ]
};

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    this.installed = true;
  },
  name: "Liberamerkato",
  route: "listings",
  component: "Listings",
  menu: [
    {
      title: "Publicaciones",
      id: "lime-listings",
      icon: false,
      menu: [
        {
          title: "Todas las publicaciones",
          name: 'listings-all',
          route: "listings"
        },
        {
          title: "Mis publicaciones",
          name: 'listings-own',
          route: "listings-own"
        },
        {
          title: "Publicar",
          name: 'listings-add',
          route: "listings-add"
        }
      ]
    },
    questionsMenu,
    transactionsMenu
  ],
  routes: [
    {
      path: "/",
      name: "lime",
      component: LimeHome,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    },
    {
      path: "/listings",
      name: "listings",
      component: Listings,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    },
    {
      path: "/cart",
      name: "cart",
      component: Cart,
      meta: {
        title: "Cart",
        requiresAuth: true
      }
    },
    {
      path: "/auctions",
      name: "auctions",
      component: Auctions,
      meta: {
        title: "Auctions",
        requiresAuth: true
      }
    },
    {
      name: "listings-add",
      path: "/listings/add",
      component: ListingNew,
      meta: {
        requiresAuth: true,
        title: "Publicar"
      }
    },
    {
      path: "/listings/:id/edit",
      name: "listings-edit",
      component: ListingEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/listings/:id/upload",
      name: "listing-upload",
      component: ListingCrop,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/listings/own",
      name: "listings-own",
      component: ListingsOwn,
      meta: {
        title: "Inicio",
        requiresAuth: true
      }
    },
    {
      path: "/listings/:id",
      name: "listing-view",
      component: ListingView,
      meta: {}
    },
    {
      path: "/questions",
      name: "questions-received",
      component: QuestionsReceived,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/questions/asked",
      name: "questions-asked",
      component: QuestionsAsked,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sales",
      name: "sales",
      component: Sales,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sales/:id",
      name: "sales-detail",
      component: SalesDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/purchases/:id",
      name: "purchases-detail",
      component: PurchasesDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/purchases",
      name: "purchases",
      component: Purchases,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      /*children: [{
        path: "addresses",
        name: "settings-addresses",
        component: Addresses
      }],*/
      meta: {
        requiresAuth: true
      }
    }
  ]
};

