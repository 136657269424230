<template>
  <div>
    <h2>Carrito</h2>
    <ul class="grid-list cart-items">
      <li v-for="item in cart.items" v-bind:key="item.id">
        <div>
          <img :src="item.imageUrl" />
        </div>
        <div class="cart-item-title">
          {{item.title}}
        </div>
        <div class="cart-item-quantity">
          <div><button type="button" @click="decrement(item.id)">-</button></div>
          <div>{{ item.quantity }}</div>
          <div><button type="button" @click="increment(item.id)">+</button></div>
        </div>
        <div>
          {{ item.currency.symbol }}
          {{ item.price }}
        </div>
        <div>
          <button type="button" @click="remove(item.id)">Quitar</button>
        </div>
      </li>
      <li v-if="!cart.items">
        El carrito está vacio
      </li>
    </ul>
    <div class="form-actions" v-if="cart.items">
      <button v-on:click="checkout" class="primary">Finalizar compra</button>
    </div>
  </div>
</template>

<script>
import { useCartStore } from '../stores/cart';

export default {
  name: "ShowCartModal",
  id: "cart-modal",
  computed: {
    store() {
      return useCartStore();
    },
    cart() {
      return this.store.cart;
    }
  },
  methods: {
    checkout() {
      this.$router.push({name: "cart"});
      this.resolve("checkout");
    },
    remove(listingId) {
      this.store.removeItem(listingId).then(function(){
        
      })
    },
    increment(listingId) {
      const self = this;
      this.store.incrementQuantity(listingId).then(function () {

      }).catch(function(response) {
        self.$notifications.error(response.data.error);
      })
    },
    decrement(listingId) {
      this.store.decrementQuantity(listingId).then(function () {

      })
    }
  }
}
</script>

<style>
.cart-items li {
  grid-template-columns: 90px 3fr 1fr 1fr 1fr;
  gap: 1rem;
}
.cart-item-quantity {
  display: flex;
  gap: 1rem;
  align-items: top;
}
</style>