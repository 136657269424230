<template>
  <div id="notifications-modal">
    <h2>Notificaciones</h2>
    <ul class="grid-list">
      <li class="" v-for="not in notifications" :key="not.id">
        <div class="">
          <router-link v-if="not.data" @click="seen(not.id)" :to="{'name':not.data.route.name}">
            {{not.message}}
          </router-link>
          <div v-if="!not.data">{{not.title}}</div>
          <div>{{not.created_at}}</div>
        </div>
        <div class="actions">
          <button v-if="not.seen === 0" title="Marcar como leido" @click="seen(not.id)" class="primary" type="button">
            <i class="bi bi-check-lg"></i>
          </button> 
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useNotificationStore } from '../stores/notification';

export default {
  name: "Notifications",
  id: "notifications",
  methods: {
    seen(notificationId) {
      this.store.markAsSeen(notificationId);
    }
  },
  computed: {
    store() {
      return useNotificationStore();
    },
    notifications() {
      return this.store.all;
    }
  }
}
</script>