<template>
  <div class="widget-content">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "WidgetContent",
};
</script>
