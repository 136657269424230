<template>
  <div class="reset-modal window-modal">
    <h2>Resetear clave</h2>
    <FormWrapper :error="error">
      <FormRow>
        <FormEmail ref="email" label="Correo electrónico" v-model="email">
        </FormEmail>
      </FormRow>
      <FormActions>
        <button type="button" v-on:click="cancelar()">
          Cancelar
        </button>
        <button type="button" class="primary" v-on:click="submit()">
          Enviar código
        </button>
      </FormActions>
    </FormWrapper>
  </div>
</template>
<script>
import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "ResetModal",
  id: "reset-modal",
  mixins: [FormWrapperMixin],
  data() {
    return {
      el: "reset",
      error: {},
      email: ""
    };
  },
  props: {
    mail: {
      type: String
    }
  },
  computed: {
    store() {
      return useAuthStore(this.$pinia);
    }
  },
  methods: {
    cancelar() {
      console.log(this);
      this.reject("Cancelled");
    },
    submit() {
      if (this.validate()) {
        this._submit();
      }
    },
    _submit() {
      this.error = {};
      var self = this;
      
      this.store.reset(self.email)
        .then(function (msg) {
          let obj = {
            props: {
              email: self.email
            }
          };
          
          self.$auth.newPassModal(obj)
            .then(function (res) {
              console.log('verify reset', res);
              self.resolve(res);
            })
            .catch(function (err) {
              console.log('err', err);
              self.errors = err;
              self.reject(err);
            });
        })
        .catch(function (err) {
          console.log('err', err);
          self.error = err;
        });

    }
  }
};
</script>
