<template>
    <div class="alert" :class="css">
        <slot></slot>
    </div>
</template>

<script lang="js">
export default {
    name: "AlertWrapper",
    props: {
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        css() {
            let styles = {}
            let c = `alert-${this.type}`;
            styles[c] = true;
            return styles;
        }
    }
}
</script>