<template>
  <div id="chat-conversation" tabindex="-1">
    <h2>Chat</h2>
    <div class="modal-body">
      <div class="chat-messages" style="min-height: 100px">
        <div class="chat-message" :class="sender(m)" v-for="(m, idx) in messages" v-bind:key="idx">
          {{m.message}}
        </div>
      </div>
    </div>
    <form>
      <input type="text" class="form-control" v-model="message" />
      <div class="button-group">
        <button
          type="button"
          class="btn btn-secondary"
          @click="close()"
        >
          Cerrar
        </button>
        <button type="button" class="primary" v-on:click="send()">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useChatStore } from '../../stores/chat';
import { useAuthStore } from '@linkedcode/auth/src/stores//auth'

export default {
  name: "ChatConversation",
  id: "chat-conversation",
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      messages: [],
      message: ""
    }
  },
  methods: {
    sender(m) {
      const userId = this.authStore.userId;
      let classes = {};
      if (m.from.id === userId) {
        classes['fromme'] = true;
      }
      return classes;
    },
    send() {
      const self = this;
      this.store.startChat(this.userId, this.message).then(function() {
        self.message = "";
        self.load();
      });
    },
    load() {
      const self = this;
      this.store.loadChatWithUserId(this.userId).then(function(chat){
        self.messages = chat.messages;
      }).catch(function(err) {
        //debugger;
        self.messages = [];
        //console.log(err);
      });
    },
    close() {
      this.reject("btn close");
    }
  },
  computed: {
    store() {
      return useChatStore();
    },
    authStore() {
      return useAuthStore();
    }
  },
  mounted() {
    this.load();
  }
};
</script>
<style>
.chat-message {
  margin-top: 0.5rem;
}
.chat-messages {
  margin-bottom: 0.5rem;
}
.fromme {
  text-align: right;
}
</style>
