<template>
  <PageFull title="Ultimas publicaciones">
    <GridCard class="listings-cards">
      <ListingItemCard v-for="listing in items" :key="listing.id" v-bind:item="listing" />
    </GridCard>
  </PageFull>
</template>

<script>
import ListingItem from "../components/ListingItem.vue"
import ListingItemCard from "../components/ListingItemCard.vue"
import { useListingStore } from "../stores/listings";

export default {
  mounted() {
    this.store.loadNewest();
  },
  computed: {
    store() {
      return useListingStore(this.$pinia);
    },
    items() {
      return this.store.newest;
    },
  },
  components: {
    ListingItem,
    ListingItemCard
  },
};
</script>
