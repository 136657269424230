<template>
  <div id="chat-applet">
    <menu-base :menu="menu" @open="open" @conversation="conversation"></menu-base>
  </div>
</template>
<script>
import ChatAppletModal from "./ChatAppletModal.vue";
import { useChatStore } from '../stores/chat';
import Conversation from "./modals/Conversation.vue";

export default {
  requireAuth: true,
  computed: {
    chats: function () {
      return this.chatStore.pending;
    },
    chatStore() {
      return useChatStore(this.$pinia);
    },
    menu: function () {
      var submenu = [];

      if (this.$auth.isAuthed()) {
        if (this.chats.length === 0) {
          submenu.push({ title: 'No hay mensajes sin leer' });
        } else {
          this.chatStore.pending.forEach(function(chat) {
            console.log('last', chat.last);
            submenu.push({ 
              title: chat.last.from.name + ': ' + chat.last.message,
              method: 'conversation',
              param: chat.last.from.id
            });
          })
          submenu.push({ title: 'Ver todos', method: 'open' });
        }
      } else {
        return [];
      }

      var menu = [{
        route: null,
        icon: 'bi bi-chat',
        position: 'end',
        menu: submenu,
        //method: 'open',
        badge: this.chats.length
      }];

      //console.log(menu);

      return menu;
    }
  },
  data() {
    return {};
  },
  methods: {
    open() {
      this.$openModal({
        component: ChatAppletModal,
        props: {
          chats: this.chats
        }
      });
    },
    conversation(userId) {
      //console.log('conversation with userId', userId);
      this.$openModal({
        component: Conversation,
        props: {
          userId: userId
        }
      }).catch(function (err) {
        self.load();
      }).then(function (err) {
        self.load();
      })
    },
    load() {
      this.chatStore.pending().catch(function (err) {
        console.log(err);
      });
    }
  },
  mounted() {
    if (this.$auth.isAuthed()) {
      this.load();
    }
  }
};
</script>