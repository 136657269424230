<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <div v-for="option in options" :key="option.id" class="form-checkbox-wrapper">
            
            <input 
                type="checkbox"
                :checked="isChecked(option.value)"
                :value="option.value" @input="emitValueCheckbox" :id="option.id" :ref="option.id" v-bind="$attrs" />
            <label class="form-checkbox-label" :for="option.id">
                {{ option.label }}
            </label>
        </div>


    </FormControlWrapper>
</template>

<script>
/*:value="modelValue"*/
import { toRaw } from 'vue';
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    name: "FormCheckbox",
    mixins: [FormControlMixin],
    components: [
        FormControlWrapper
    ],
    props: {
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        isChecked(value) {
            console.log('isChecked', this.modelValue, value);
            let arr = toRaw(this.modelValue);
            return arr.includes(value);
        }
    }
}

</script>