<template>
  <div class="grid-list">
    <div class="grid-list-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div class="grid-list-body">
      <ul class="grid-list-items">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "GridList"
};
</script>