<template>
  <div class="listing-item my-2 border-bottom py-2">
    <div class="row">
      <div class="listing-data col-10">
        <div class="hstack gap-2">
          <div class="listing-image">
            <img width="90" height="90" v-bind:src="thumb(item)" />
          </div>
          <div class="listing-title align-self-start">
            <!--router-link :to="{ name: 'listing-detail', params: { id: item.id } }">{{
              item.title
            }}</router-link-->
            <a href="#" @click.prevent="view(item.id)">{{item.title}}</a>
          </div>
        </div>
      </div>
      <div class="listing-price col-2 text-right text-end">
        <span v-if="item.currency">
          {{ item.currency.symbol }} {{ item.price }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ListingItem",
  id: "listing-item",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    thumb(img) {
      return this.$config.IMG_URL + img.image_url
    },
    view(id) {
      this.$openModal({
        component: "",
        props: { listingId: id },
        width: 80
      });
    }
  }
};
</script>
