import { useAddressStore } from "./store";
import AddressEditForm from "./views/AddressEditForm.vue";
import AddressSelect from "./components/AddressSelect.vue";
import MyAddresses from "./views/MyAddresses.vue"
import { markRaw } from 'vue'

export default {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    const store = useAddressStore(app.config.globalProperties.$pinia);

    app.component("address-edit-form", AddressEditForm);
    app.component("address-select", AddressSelect);

    /*var f = function() {
      this.$openModal({
        component: MyAddresses,
        cssClass: ['open-on-right']
      }).then(function (a) {
        console.log(a);
      });
    }*/

    if (options.settingsStore) {
      options.settingsStore.add({
        name: 'Mis direcciones',
        description: 'Administra tus direcciones',
        component: markRaw(MyAddresses),
        icon: {
          'bi bi-lock big-icon': true
        }
      });
    } else {
      console.warn('No hay storeSettings');
    }

    app.config.globalProperties.$address = {
      format: function(address) {
        return store.format(address);
      },
      politicalFormat: function(address) {
        return store.politicalFormat(address);
      },
      line1: function(address) {
        return store.formatLine1(address);
      },
      line2: function(address) {
        return store.formatLine2(address);
      }
    };

    this.installed = true;
  }
}
