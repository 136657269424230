import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
  state: () => {
    return { 
      cart: {
        items: []
      }
    }
  },
  actions: {
    addToCart(payload) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`/carts`, payload).then(function(response) {
          self.loadCart();
          resolve(response.data)
        }).catch(function (err) {
          reject(err.response.data);
        });;
      });
    },
    loadCart() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`/carts`).then(function (response) {
          self.cart = response.data;
          resolve("ok");
        });
      });
    },
    removeItem(listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.delete(`/carts/items/${listingId}`).then(function() {
          self.loadCart();
          resolve("ok")
        });
      });
    },
    incrementQuantity(listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`/carts/items/${listingId}/increment`).then(function() {
          self.loadCart();
          resolve("ok");
        }).catch(function(err) {
          reject(err.response);
        });
      });
    },
    decrementQuantity(listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`/carts/items/${listingId}/decrement`).then(function() {
          self.loadCart();
          resolve("ok");
        });
      });
    },
    checkout() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`/carts/checkout`).then(function() {
          resolve("ok")
        });
      });
    }
  }
})