<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <textarea :class="css" :value="modelValue" @input="emitValue" v-bind="$attrs"></textarea>
    </FormControlWrapper>
</template>

<script>

import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    mixins: [FormControlMixin],
    components: [
        FormControlWrapper
    ]
}

</script>