<template>
  <div id="notification-applet">
    <menu-base @viewAll="viewAll" :menu="menu"></menu-base>
  </div>
</template>

<script>
import Notifications from "./../views/Notifications.vue";
import { useNotificationStore } from "../stores/notification";

export default {
  requireAuth: true,
  data() {
    return {
      
    };
  },
  computed: {
    store() {
      return useNotificationStore();
    },
    count() {
      return this.notifications.length;
    },
    notifications() {
      return this.store.unseen;
    },
    icon() {
      return {
        "bi-bell-fill": this.count,
        "bi-bell": !this.count,
      };
    },
    menu() {
      //console.log(this.count);
      //var icon = this.icon();
      var submenu = [];
      var badge = false;

      if (this.count) {
        badge = this.count;
        console.log(this.notifications);
        this.notifications.forEach(element => {
          let item = { title: element.title };
          if (element.data && element.data.route) {
            item.route = element.data.route.name;
          }
          submenu.push(item);
        });
      } else {
        submenu.push({ title: 'No hay notificaciones sin ver' });
      }

      submenu.push({ title: 'Ver todas', method: 'viewAll' });

      var menu = [{
        title: "",
        id: "",
        position: 'end',
        route: null,
        //method: false,
        icon: "bi bi-bell",
        menu: submenu,
        badge: badge
      }];

      //console.log(menu);
      return menu;
    }
  },
  methods: {
    load() {
      //console.log('notifications', this.$auth.isAuthed)
      if (this.$auth.isAuthed()) {
        this.store.loadUnseen();
      }
    },
    viewAll() {
      var self = this;
      this.store.loadAll().then(function () {
        self.$openModal({
          component: Notifications,
        });
      });
    },
    show() {
      console.log("show");
    },
  },
  mounted() {
    this.load();
  },
};
</script>