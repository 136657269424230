<template>
  <div>
    <div v-if="open" 
      class="backdrop"
      v-bind:class="backdropClass"
      @click="onBgClicked"
    ></div>
      <div
        v-for="modal in modals"
        :class="getClasses(modal)"
        class="layer-modal"
        :style="modal.style"
        :key="modal.component.id"
      >
          <component
            v-bind:is="modal.component.id"
            v-bind="modal.props"
          ></component>
      </div>
  </div>
</template>

<script>
import { useModalStore } from "./../stores/modal";

export default {
  name: "LayerModal",
  data() {
    return {
      props: {}
    };
  },
  computed: {
    backdropClass() {
      let cssClass = "";

      if (this.modals.length) {
        cssClass = ` level${this.modals.length}`;
      }

      return cssClass;
    },
    open() {
      return this.modals.length > 0;
    },
    store() {
      return useModalStore();
    },
    modals() {
      return this.store.getModals;
    },
    level() {
      return this.store.level;
    }
  },
  created() {
    document.addEventListener("keydown", this.onEscapeKeypress);
  },
  methods: {
    
    append(component) {
      this.store.addModal(component);
      
    },
    getClasses(modal) {
      //console.log(modal);
      const css = {};
      if (modal.cssClass) {
        modal.cssClass.forEach(function(cls) {
          css[cls] = true;
        });
      } else {
        
      }

      let level = this.level - 1;
      if (level > 1) {
        css[`max-level-${level}`] = true;
      }

      return css;
    },
    onEscapeKeypress(e) {
      if (e.keyCode === 27) {
        //console.log('LayerModal: onEscapeKeypress');
        this.escape();
      }
    },
    onBgClicked(evt) {
      console.log("onBgClicked");
      this.escape();
    },
    escape() {
      this.store.escape();
    },
    
  },
  unmounted() {
    document.removeEventListener("keydown", this.onEscapeKeypress);
  }
};
</script>
