<template>
    <div class="dt-wrapper">
        <div class="dt-title">{{ title }}</div>
        <div class="dt-definition">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DefinitionItem",
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>