<template>
  <header>
    <!--hamburger></hamburger-->
    <div id="brand">
      <router-link to="/">liberamerkato</router-link>
    </div>
    <navbar-menu />
    <system-area></system-area>
  </header>
  <router-view />
  <footer>
    liberamerkato
  </footer>
  <layer-modal></layer-modal>
</template>