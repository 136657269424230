<template>
    <div class="form-control-wrapper">
        <label v-if="label">{{ label }}</label>
        <slot></slot>
        <ul v-if="errors.length > 0 || apiErrorsProp.length > 0" class="form-control-errors">
            <li v-for="err in errors">{{ err }}</li>
            <li v-for="err in apiErrorsProp">{{ err.reason }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "FormControlWrapper",
    data() {
        return {
        }
    },
    props: {
        errors: {
            type: Array,
            default: []
        },
        apiErrorsProp: {
            type: Array,
            default: []
        },
        label: {
            type: String,
            default: ""
        }
    }
}
</script>
