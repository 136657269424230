<template>
    <div class="grid-card-item">
        <div class="grid-card-item-image" v-if="$slots.image">
            <slot name="image"></slot>
        </div>
        <div class="grid-card-item-title" v-if="title" :class="titleCss">
            <h2><router-link :to="link">{{ title }}</router-link></h2>
        </div>
        <div class="grid-card-item-body">
            <slot></slot>
        </div>
        <div class="grid-card-item-actions" v-if="$slots.actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "GridCardItem",
    props: {
        title: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true
        }
    },
    computed: {
        titleCss() {
            //console.log(this.$slots.image)
            return {
                'no-image': this.$slots.image === undefined
            }
        }
    }
}
</script>
