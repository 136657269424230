<template>
    <div class="definition-list-wrapper">
        <div v-if="title" class="definition-list-title">
            <h2>{{ title }}</h2>
        </div>
        <div class="definition-list-body">
            <dl>
                <template v-for="(definition, term) in terms" :key="term">
                    <dt>{{ term }}</dt>
                    <dd>{{ definition }}</dd>
                </template>
            </dl>
        </div>
    </div>
</template>

<script>
export default {
    name: "DefinitionList",
    props: {
        title: {
            type: String
        },
        terms: {
            type: Object
        }
    },
    mounted() {
        //console.log(this.terms);
    }
}
</script>