<template>
  <ModalWindow title="Perfil">
    <user-profile-form @resolve="resolve"></user-profile-form>
  </ModalWindow>
</template>

<script>
import UserProfileForm from '../component/UserProfileForm.vue';
export default {
  components: { UserProfileForm },
  name: 'UserProfile',
  id: 'settings-user-profile',
  methods: {}
}
</script>