<template>
  <div class="modal1" id="address-edit" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar dirección</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
        </div>
        <div class="modal-footer">
          <button type="button" @click="close()">Cerrar</button>
          <button type="button" @click="save()">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressEdit",
  id: "address-edit",
  props: {
    item: {
      type: Object,
      required: false,
      default(rawProps) {
        //console.log(rawProps);
        return {
          country_id: 0,
          province_id: 0,
          city_id: 0,
        }
      }
    }
  },
  computed: {
    countries() {
      return this.$store.state.address.countries;
    },
    provinces() {
      return this.$store.state.address.provinces;
    },
    cities() {
      return this.$store.state.address.cities;
    },
  },
  methods: {
    close() {
      this.reject("Closed");
    },
    updateProvinces() {
      this.$store.dispatch("address/provinces", {
        country_id: this.item.country_id,
      });
    },
    updateCities() {
      this.$store.dispatch("address/cities", this.item);
    },
    save() {
      var self = this;
      this.$store.dispatch("address/save", { item: this.item }).then(function(msg){
        self.resolve(msg);
      });
    },
  },
  mounted() {
    this.$store.dispatch("address/countries");
    if (this.item.country_id) {
      this.updateProvinces();
    }
    if (this.item.province_id) {
      this.updateCities();
    }
  },
};
</script>