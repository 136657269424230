<template>
    <form class="form-wrapper">
        <div class="form-error" v-if="showError()">
            <div class="form-error-title">{{ error.title }}</div>
            <div v-if="error.detail" class="form-error-detail">{{ error.detail }}</div>
        </div>
        <slot></slot>
    </form>
</template>
<script>
export default {
    props: {
        error: {
            type: Object,
            default: {
                status: 200
            }
        }
    },
    watch: {
        error(nue, vie) {
            //console.log('watch', 'error');
            const self = this;

            if (typeof nue['invalid-params'] === 'undefined') {
                return;
            }

            nue['invalid-params'].forEach(function (error, idx) {
                const obj = self.findRefByName(error.name);
                obj.apiErrors.length = 0;
            })

            nue['invalid-params'].forEach(function (error, idx) {
                const ref = self.findRefByName(error.name);
                ref.addError(error);
            })
        }
    },
    methods: {
        showError() {
            //console.log(this.error);
            return this.error.status >= 400;
        },
        findRefByName(refName) {
            let obj = this
            while (obj) {
                if (obj.$refs[refName]) {
                    return obj.$refs[refName]
                }
                obj = obj.$parent
            }
            return undefined
        }
    }   
}
</script>