<template>
    <PageFull title="Aplicaciones">
        <ul>
            <li v-for="app in apps" :key="app.name">
                <a @click="activate(app)">
                    <div><i :class="getIcon(app)"></i></div>
                    <div>{{ app.name }}</div>
                </a>
            </li>
        </ul>
    </PageFull>
</template>

<script>
import { useAppsStore } from '../store';

export default {
    computed: {
        store() {
            return useAppsStore(this.$pinia);
        },
        apps() {
            return this.store.applications;
        }
    },
    methods: {
        activate(app) {
            this.store.activate(app);
        },
        getIcon(app) {
            console.log(app);
            let css = {}
            css[app.icon] = true;
            console.log(css);
            return css;
        }
    }
}
</script>