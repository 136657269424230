<template>
  <div class="sidebar-wrapper">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "SidebarWrapper",
};
</script>
