import SystemArea from "./SystemArea.vue";
import { useSystemAreaStore } from "./store";

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;

    app.component("system-area", SystemArea);

    const systemArea = {
      add: function(applet) {
        const store = useSystemAreaStore(app.config.globalProperties.$pinia);
        store.add(applet);
      }
    };

    if (options && options.applets) {
      options.applets.forEach(function(applet) {
        systemArea.add(applet);
      }, this);
    }

    app.config.globalProperties.$systemArea = systemArea;

    this.installed = true;
  }
};
