<template>
	<div class="form-button">
		<button v-bind="$attrs">
			<slot></slot>
		</button>
	</div>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';

export default {
	name: "FormButton",
	inheritAttrs: false
}

</script>
