<template>
    <div class="select-action">
        <div v-if="label" class="label">{{ label }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SelectAction",
    props: {
        label: {
            type: String
        }
    }
}
</script>

<style>
.select-action {
    display: flex;
    flex-direction: column;
}
.select-action > .label {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
}
</style>