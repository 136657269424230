import axios from "axios";
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

const http = axios.create();

export default {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    http.defaults.baseURL = options.api.url;
    loadProgressBar({}, http);

    app.config.globalProperties.$pinia.use(() => ({ $http: http }));
    app.config.globalProperties.$http = http;

    this.installed = true;
  }
}