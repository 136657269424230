<template>
  <FormRow>
    <FormSelect ref="_address" label="Seleccione una dirección" v-model.number="addressId" required="true"
      :options="options" @input="emitValue">

      <template #buttons>
        <FormButton type="button" @click="newAddress()">
          Agregar nueva dirección
        </FormButton>
      </template>
    </FormSelect>
  </FormRow>
</template>

<script>
import AddressAddEditForm from "../components/AddressAddEditForm.vue";
import { useAddressStore } from "../store";

export default {
  data() {
    return {
      addressId: this.modelValue,
    };
  },
  emits: ['update:modelValue', 'addressesCount'],
  props: {
    modelValue: {
      type: Number,
      required: true,
      validator(value) {
        return true;
      },
    },
  },
  methods: {
    emitValue(e) {
      this.$emit('update:modelValue', parseInt(e.target.value))
    },
    validate() {
      var valid = this.$refs._address.validate();
      return valid;
    },
    load() {
      const self = this;
      this.store.load().then(function(res) {
        self.$emit('addressesCount', self.store.own.length);
      });
    },
    newAddress() {
      const self = this;
      this.$openModal({
        component: AddressAddEditForm
      }).then(function () {
        self.load();
      }).catch(function(err) {
        //console.log(err);
      });
    },
  },
  computed: {
    options() {
      var opts = [{
        value: 0,
        name: ' - Seleccionar - '
      }];
      this.addresses.forEach(function(a) {
        opts.push({
          value: a.id,
          name: `${a.title} - ${a.street} ${a.number}`
        });
      });
      return opts;
    },
    store() {
      return useAddressStore(this.$pinia);
    },
    addresses() {
      return this.store.own;
    },
  },
  mounted() {
    this.load();
  },
};
</script>