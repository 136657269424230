<template>
    <div class="form-input-group">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "FormInputGroup",
}

</script>