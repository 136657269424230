import FormWrapper from "./FormWrapper.vue";
import FormRow from "./FormRow.vue";
import FormRowGrid from "./FormRowGrid.vue";
import FormCol from "./FormCol.vue";
import FormFielset from "./FormFieldset.vue";
import FormActions from "./FormActions.vue";
import FormControlWrapper from "./FormControlWrapper.vue";
import FormInput from "./FormInput.vue";
import FormTextarea from "./FormTextarea.vue";
import FormEmail from "./FormEmail.vue";
import FormPassword from "./FormPassword.vue";
import FormSelect from "./FormSelect.vue";
import FormButton from "./FormButton.vue";
import FormInputGroup from "./FormInputGroup.vue";
import FormCheckbox from "./FormCheckbox.vue";

import FormWrapperMixin from "./FormWrapperMixin.vue";
import FormRowHelp from "./FormRowHelp.vue";

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    app.component("FormWrapper", FormWrapper);
    
    app.component("FormRow", FormRow);
    app.component("FormRowHelp", FormRowHelp);

    app.component("FormCol", FormCol);
    app.component("FormRowGrid", FormRowGrid);
    app.component("FormFieldset", FormFielset);
    app.component("FormActions", FormActions);
    app.component("FormInputGroup", FormInputGroup);

    app.component("FormControlWrapper", FormControlWrapper);

    app.component("FormEmail", FormEmail);
    app.component("FormInput", FormInput);
    app.component("FormCheckbox", FormCheckbox);
    app.component("FormTextarea", FormTextarea);
    app.component("FormPassword", FormPassword);
    app.component("FormSelect", FormSelect);
    app.component("FormButton", FormButton);

    this.installed = true;
  }
};

export { FormWrapperMixin };
