import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem("token") || null,
    userMenu: []
  }),
  computed: {
    isAuthed: function() {
      return this.userId > 0;
    },
  },
  getters: {
    jwt: (state) => state.token,
    jwtData: function(state){
      return state.token ? JSON.parse(atob(state.token.split(".")[1])) : null
    },
    jwtSubject: (state) => state.jwtData ? state.jwtData.sub : null,
    jwtIssuer: (state) => state.jwtData ? state.jwtData.iss : null,
    userId: (state) => (state.jwtData ? parseInt(state.jwtData.sub) : 0),
    isAuthed: function() {
      if (this.jwtData && this.jwtData.sub) {
        return true;
      }
      return false;
    }
  },
  actions: {
    setToken(jwt) {
      this.token = jwt;
    },
    saveNewPass(email, password, code) {
      const self = this;
      const payload = { email, password, code };
      
      return new Promise(function(resolve, reject) {
        self.$http.post('/auth/reset/save', payload).then(function(response){
          resolve("ok");
        }).catch(function(err) {
          reject(err);
        });
      });
    },
    login(email, password) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post('/login', { email, password }).then(function(response){
          console.log('login:then');
          self.handleAuthentication(response).then(function(jwt) {
              console.log('login:then handleAuthentication:then');
              //dispatch('notifications/success', { msg: 'Estás dentro!' }, { root: true })
              self.token = jwt;
              localStorage.setItem("token", jwt);
              resolve(jwt);
            })
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    logout() {
      this.token = null;
      localStorage.removeItem("token");
    },
    verifycode({ commit }, { email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .verifynewcode(email, password)
          .then(function(jwt) {
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    register(email, password) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post('/register', { email, password })
          .then(function(jwt) {
            //dispatch('notifications/success', {msg:'Registro exitoso'}, {root:true})
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    resetsave({ commit, dispatch }, { code, email, password }) {
      return new Promise(function(resolve, reject) {
        userService
          .resetsave(email, password, code)
          .then(function(jwt) {
            //dispatch('notifications/success', { msg: 'Contraseña cambiada con exito' }, { root: true })
            resolve("OK");
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    code(email) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post('/auth/newcode', { email })
          .then(function(jwt) {
            resolve(jwt);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    verify(email, password, code) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post("/verify", { email, password, code })
          .then(function(response) {
            console.log('login:then');
            self.handleAuthentication(response)
              .then(function(jwt) {
                console.log('verify:then handleAuthentication:then');
                //dispatch('notifications/success', { msg: 'Estás dentro!' }, { root: true })
                self.token = jwt;
                localStorage.setItem("token", jwt);
                resolve(jwt);
              })
              .catch(function(err) {
                reject(err);
              });
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },
    reset(email) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post('/auth/reset', { email })
          .then(function(response) {
            resolve(response);
          })
          .catch(function(err) {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
    handleAuthentication(response) {
      return new Promise((resolve, reject) => {
        if (response.data && response.data.access_token) {
          resolve(response.data.access_token);
        } else {
          reject(response.data.errors);
        }
      });
    }
  }
});
