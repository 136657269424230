<script>
export default {
    data() {
        return {
            errors: [],
            apiErrors: []
        }
    },
    props: {
        modelValue: {
        },
        label: {
            type: String,
            default: ""
        }
    },
    emits: ['update:modelValue'],
    computed: {
        css() {
            return {
                'form-input-error': (this.errors.length > 0 || this.apiErrors.length > 0),
                'form-input-valid': this.errors.length === 0 && this.apiErrors.length === 0,
            };
        }
    },
    methods: {
        addError(err) {
            console.log('addError', err);
            this.apiErrors.push(err);
        },
        emitValueCheckbox(e) {
            let value = [];
            console.log(this.$refs);
            for (var refname in this.$refs) {
                var ref = this.$refs[refname];
                console.log('checked', refname, ref[0].checked);
                if (ref[0].checked === true) {
                    value.push(ref[0].value);
                }
            }
            this.$emit('update:modelValue', value);
        },
        emitValue(e) {
            let value = e.target.value;
            console.log('emitvalue', value);
            this.$emit('update:modelValue', value);
            this._reset();
            this._validateValue(value);
        },
        validate() {
            console.log('validate');
            this._reset();
            return this._validateValue(this.modelValue);
        },
        _validateValue(value) {
            return this._validateBase(value);
        },
        _reset() {
            this.errors.length = 0;
            this.apiErrors.length = 0;
        },
        _validate() {
            this._validateBase(this.modelValue);
        },
        _validateBase(value) {
            //console.log('_validateBase', value);
            
            for (var attr in this.$attrs) {
                //console.log('attr', attr);
                switch (attr) {
                    case 'required': {
                        //this.validateRequired(this.$attrs[attr], value);
                    }
                }
            }

            if (typeof this.$attrs.required != "undefined") {
                console.log("required", this.$attrs.required, value);
                if (this.$attrs.required && value === "") {
                    this.errors.push("Este campo es requerido.");
                }
            }

            if (typeof this.$attrs.maxlength != "undefined") {
                console.log("maxlength", this.$attrs.maxlength, value);
                if (value.length > parseInt(this.$attrs.maxlength)) {
                    this.errors.push("Longitud maxima excedida: " + this.$attrs.maxlength);
                }
            }
            if (typeof this.$attrs.minlength != "undefined") {
                console.log("minlength", this.$attrs.minlength, value);
                if (value.length < parseInt(this.$attrs.minlength)) {
                    this.errors.push("Longitud minima no alcanzada: " + this.$attrs.minlength);
                }
            }

            if (typeof this.$attrs.min != "undefined") {
                console.log("min", this.$attrs.min, value);
                if (value < parseInt(this.$attrs.min)) {
                    this.errors.push("Minimo no alcanzado: " + this.$attrs.min);
                }
            }

            if (typeof this.$attrs.required != "undefined") {
            }

            return (this.errors.length === 0);
        },
        findRefByName(refName) {
            let obj = this
            while (obj) {
                if (obj.$refs[refName]) {
                    return obj.$refs[refName]
                }
                obj = obj.$parent
            }
            return undefined
        }
    }
}
</script>