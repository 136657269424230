<template>
  <li v-if="item.menu" v-bind:class="{ active: this.active }">
    <a v-on:click="toggle(item)">
    <i v-if="item.icon" :class="item.icon"></i>
    <span v-if="item.title">{{ item.title }}</span>
    <span v-if="item.badge" class="badge">{{item.badge}}</span>
    </a>
    <ul v-if="this.show" :class="item.position">
      <li v-for="subitem in item.menu" :key="subitem.name">
        <a v-if="subitem.method" @click="emit(subitem.method, subitem.param)">
          {{ subitem.title }}
        </a>
        <a v-if="!subitem.method && !subitem.route">
          {{ subitem.title }}
        </a>
        <router-link v-if="subitem.route" :to="{ name: subitem.route }" @click="close">
          {{ subitem.title }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: false
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.remove();
  },
  methods: {
    emit(v, param) {
      this.$emit('emit', v, param);
    },
    toggle() {
      this.show = !this.show;
      this.active = !this.active;
      if (this.show) {
        document.addEventListener("click", this.clickHandler);
      } else {
        this.remove();
      }
    },
    clickHandler(event) {
      ///console.log("clickHandler");
      const { target } = event;
      const { $el } = this;
      if (!$el.contains(target)) {
        this.remove();
      }
    },
    close() {
      this.remove();
    },
    remove() {
      this.show = false;
      this.active = false;
      document.removeEventListener("click", this.clickHandler);
    },
  },
};
</script>