<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <div class="form-select-control">
            <select :class="css" :value="modelValue" @input="emitValue" v-bind="$attrs">
                <option v-for="o in options" :value="o.value" :key="o.value">{{ o.name }}</option>
            </select>
            <div v-if="$slots.buttons" class="form-select-control-buttons">
                <slot name="buttons">
                </slot>
            </div>
        </div>
    </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    inheritAttrs: false,
    mixins: [FormControlMixin],
    name: "FormSelectWrapper",
    props: {
        options: {
            type: Array,
            required: true,
        },
        nullValue: {
            type: Boolean,
            default: false,
        }
    },
    components: { FormControlWrapper }
};
</script>
