import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notifications', {
  state: () => ({
    all: [],
    unseen: []
  }),
  actions: {
    loadAll() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/notifications`).then(function (response) {
          self.all = response.data;
          resolve(response.data);
        })
      })
    },
    loadUnseen() {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`/notifications`, { params: { seen: 0 }}).then(function (response) {
          self.unseen = response.data;
          resolve(response.data);
        })
      })
    },
    markAsSeen(notificationId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.put(`/notifications/${notificationId}/seen`).then(function (response) {
          resolve(response.data);
        })
      })
    }
  }
});
