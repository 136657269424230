<template>
  <div class="page-content-wrapper" :class="css">
    <slot></slot>
  </div>
</template>
<script lang="js">
export default {
  name: "PageContentWrapper",
  props: {
    withSidebar: {
      type: Boolean
    }
  },
  computed: {
    css() {
      return {
        'with-sidebar': this.withSidebar === true
      }
    }
  }
};
</script>