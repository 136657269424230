import UserProfileForm from "./component/UserProfileForm.vue";
import LoginModal from "./modals/LoginModal.vue";
import Settings from "./views/Settings.vue";
import { useAuthStore } from "./stores/auth";

export default {
  requireAuth: false,
  computed: {
    userId: function() {
      const store = useAuthStore();
      return store.userId;
    },
    isAuthed: function() {
      return this.$auth.isAuthed();
    },
    username: function() {
      var name = 'Anonimo';
      if (this.$store.getters['auth/isAuthed']) {
        if (this.$store.getters['profile/username']) {
          name = this.$store.getters['profile/username'];
        } else {
          this.$store.dispatch('profile/me');
        }
      }
      return name;
    },
    menu: function() {
      var submenu = [];
      const store = useAuthStore(this.$pinia);

      if (store.isAuthed) {
        submenu.push({ title: 'Configuración', method: 'settings' });
        submenu.push({ title: 'Cerrar sesión', method: 'logout' });
      } else {
        submenu.push({ title: 'Ingresar', method: 'login'});
        submenu.push({ title: 'Registrarse', method: 'register' });
      }

      //console.log('this', this, this.username);
      return [{
          title: "",
          route: null,
          icon: 'bi bi-person',
          position: 'end',
          menu: submenu
        }];
    }
  },
  data() {
    return {};
  },
  methods: {
    settings() {
      this.$openModal({
        component: Settings,
      }).catch(function(err){
        console.log(err)
      });
    },
    offcanvas: function() {
      //console.log('user.off', UserProfileForm);
      this.$offcanvas(UserProfileForm, { 
        title: 'Actualizar perfil',
        position: 'end',
        props: {
          telegram: true
        }
      });
    },
    logout: function() {
      const store = useAuthStore();
      store.logout();
      if (this.$route.meta.requiresAuth) {
        this.$router.push('/');
      }
    },
    login: function() {
      console.log('UserApplet:login');
      //const prom = this.$auth.loginModal();
      let prom = this.$openModal({
        component: LoginModal
      });
      //console.log(prom);
        prom
        .then(function() {
          console.log("UserApplet:login:ok");
        })
        .catch(function(err) {
          console.log("UserApplet:login:error", err);
        });
    },
    register: function() {
      console.log("user applet register");
      var self = this;
      this.$auth.registerModal()
        .then(function(data) {
          console.log("user applet register data", data);
          self.$auth.verifyModal({ props: data }).then(function(data){
            console.log(data);
          }).catch(function(err) {
            console.log(err);
          });
        })
        .catch(function(err) {
          console.log("UserApplet:register:error", err);
        });
    }
  }
};