
<template>
  <div id="chat-applet-modal" >
    <h2>Conversaciones</h2>
    <ul class="grid-list">
      <li v-for="chat in chats" :key="chat.id">
        <div class="">
          <div>{{ getUsername(chat) }}</div>
          <div>{{ chat.last.message }}</div>
        </div>
        <div class="actions">
          <button class="primary" @click="openChat(chat)">Responder</button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

import Conversation from "./modals/Conversation.vue"
import { useChatStore } from "../stores/chat";

export default {
  name: "ChatAppletModal",
  id: "chat-applet-modal",
  data() {
    return {
      chats: []
    }
  },
  computed: {
    store() {
      return useChatStore();
    }
  },
  methods: {
    getUsername(chat) {
      if (chat.userA.id === this.$auth.userId()) {
        return chat.userB.name;
      } else {
        return chat.userA.name;
      }
    },
    openChat(chat) {
      const  self = this;
      if (chat.userA.id === this.$auth.userId()) {
        var uid = chat.userB.id;
      } else {
        var uid = chat.userA.id;
      }

      
      this.$openModal({
        component: Conversation,
        props: {
          userId: uid
        }
      })
    },
    load() {
      const self = this;
      this.store.loadChats().then(function(chats){
        self.chats = chats;
      })
    }
  },
  mounted() {
    console.log(this.$auth.userId());
    this.load();
  }
};
</script>

<style>
#chat-applet-modal .grid-list li {
  grid-template-columns: 3fr 1fr;
}
</style>