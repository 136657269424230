<template>
  <div id="cart-applet">
    <ul class="menu">
      <li>
        <a href="#" v-on:click.prevent="showCart" role="button" aria-expanded="false">
          <i class="bi bi-cart"></i> 
          <span v-if="itemsCount" class="badge">{{itemsCount}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import ShowCartModal from "../views/ShowCartModal.vue"
import { useCartStore } from "../stores/cart";

export default {
  requireAuth: true,
  name: "CartApplet",
  data() {
    return {
    };
  },
  computed: {
    cart() {
      return this.store.cart;
    },
    itemsCount() {
      var q = 0;
      if (this.cart && this.cart.items) {
        q = this.cart.items.length;
      }
      return q;
    },
    visible() {
      //return this.$store.getters['auth/isAuthed'];
    },
    store() {
      return useCartStore();
    }
  },
  methods: {
    init() {
      const self = this;
      this.store.loadCart().then(function(cart) {
      });
    },
    showCart() {
      const self = this;
      this.$openModal({
        component: ShowCartModal
      }).then(function(res) {
        console.log(res);
      })
      .catch(function(res) {
        console.log(res);
      });
    }
  },
  mounted() {
    console.log('CartApplet mounted');
    /*if (!this.visible) {
      return;
    }*/
    this.init();
  }
}
</script>
<style>

</style>

