<template>
    <div class="dropdown-button">
        <div class="dropdown-button-wrapper">
            <div class="dropdown-button-primary" v-if="$slots.primary">
                <slot name="primary"></slot>
            </div>
            <div class="button-caret">
                <button type="button" @click="toggle">
                    <span v-if="label">{{ label }}</span>
                    &vellip;
                </button>
            </div>
        </div>
        <ul class="dropdown-button-menu" :class="css">
            <slot></slot>
        </ul>
    </div>
</template>
<script>
export default {
    name: "DropdownButton",
    data() {
        return {
            hidden: true
        }
    },
    props: {
        label: {
            type: [String, Boolean],
            default: false
        }
    },
    computed: {
        css() {
            return {
                'hidden': this.hidden
            }
        }
    },
    methods: {
        toggle() {
            this.hidden = !this.hidden;
        },
        clickOutside(e) {
            if (!this.$el.contains(e.target)) {
                this.hidden = true;
            }
        }
    },
    created() {
        window.addEventListener('click', this.clickOutside);
    },
    destroy() {
        window.removeEventListener('click', this.clickOutside);
    }
}
</script>
<style>
.dropdown-button-wrapper {
    display: flex;
    border: 1px solid;
}
.dropdown-button {
    position: relative;
}
.dropdown-button button, .dropdown-button .button {
    border: 0;
}
.dropdown-button-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    margin-top: 5px;
    background-color: #ccc;
}
.dropdown-button-menu li {
    list-style-type: none;
}
.hidden {
    display: none;
}
.button-caret {
    border-left: 0;
    font-weight: 900;
}
.dropdown-button-primary {
    border-right: 1px solid;
}
</style>