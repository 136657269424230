import PageTitle from "./components/PageTitle.vue"
import PageContent from "./components/PageContentWrapper.vue"
import PageWrapper from "./components/PageWrapper.vue"

import MainContent from "./components/PageContent.vue"

import ModalWindow from "./components/ModalWindow.vue"

import SidebarWrapper from "./components/SidebarWrapper.vue"

import SectionWrapper from "./components/SectionWrapper.vue"

import TagList from "./components/TagList.vue"

import ImageWrapper from "./components/ImageWrapper.vue"
import AlertWrapper from "./components/AlertWrapper.vue"

import WidgetWrapper from "./components/WidgetWrapper.vue"
import WidgetTitle from "./components/WidgetTitle.vue"
import WidgetContent from "./components/WidgetContent.vue"
import WidgetItem from "./components/WidgetItem.vue"

import GridList from "./components/GridList.vue";
import GridListItem from "./components/GridListItem.vue";
import PageContentWrapper from "./components/PageContentWrapper.vue"
import PageFull from "./components/PageFull.vue"
import GridCard from "./components/GridCard.vue"
import GridCardItem from "./components/GridCardItem.vue"
import DropdownButton from "./components/DropdownButton.vue"
import DropdownAction from "./components/DropdownAction.vue"
import SelectAction from "./components/SelectAction.vue"
import GridListTitle from "./components/GridListTitle.vue"

import DefinitionItem from "./components/DefinitionItem.vue"
import DefinitionList from "./components/DefinitionList.vue"

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    
    app.component('PageWrapper', PageWrapper);
    app.component('PageFull', PageFull);
    app.component('PageTitle', PageTitle);
    app.component('PageContentWrapper', PageContentWrapper);
    app.component('PageContent', PageContent);

    app.component('MainContent', MainContent);

    app.component("SidebarWrapper", SidebarWrapper);

    app.component('SectionWrapper', SectionWrapper);

    app.component('ModalWindow', ModalWindow);

    app.component('ImageWrapper', ImageWrapper);
    app.component('AlertWrapper', AlertWrapper);

    app.component('WidgetWrapper', WidgetWrapper);
    app.component('WidgetTitle', WidgetTitle);
    app.component('WidgetContent', WidgetContent);
    app.component('WidgetItem', WidgetItem);

    app.component('tag-list', TagList);

    app.component('GridList', GridList);
    app.component('GridListItem', GridListItem);
    app.component('GridListTitle', GridListTitle);

    app.component('GridCard', GridCard);
    app.component('GridCardItem', GridCardItem);

    app.component('DropdownButton', DropdownButton)
    app.component('DropdownAction', DropdownAction)

    app.component('SelectAction', SelectAction)

    app.component('DefinitionItem', DefinitionItem)
    app.component('DefinitionList', DefinitionList)

    this.installed = true;
  }
}

//export { ImageWrapper };
