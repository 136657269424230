<template>
  <div class="widget-title-wrapper">
    <div class="widget-title">
      {{ title }}
    </div>
    <div class=" widget-title-actions" v-if="this.$slots.actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "WidgetTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
};
</script>
