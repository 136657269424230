
<template>
  <form autocomplete="false">
    <FormRow>
      <FormInput label="Nombre de perfil" type="text" v-model="item.name" id="username" />
    </FormRow>
    <FormRow>
      <FormInput type="text" label="Telegram (publico)" v-model="item.telegram" id="telegram" name="telegram" />
    </FormRow>
    <FormActions>
      <button type="button" @click="submit" class="btn btn-primary">Enviar</button>
    </FormActions>
  </form>
</template>

<script>
import { useProfileStore } from '../stores/profile';

export default {
  name: "UserProfileForm",
  id: 'user-profile-form',
  props: {
    telegram: {
      type: Boolean
    }
  },
  data() {
    return {
      store: null,
      item: {
        name: ''
      }
    }
  },
  methods: {
    submit() {
      var self = this;
      this.store.update(this.item).then(function(item){
        self.$emit('resolve', 'OK');
      })
    }
  },
  mounted() {
    var self = this;
    this.store = useProfileStore();
    //console.log("mounted");
    this.store.load().then(function(item){
      console.log(item);
      self.item.name = item.name;
    })
  }
}
</script>