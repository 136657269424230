import LayerModal from "./components/LayerModal.vue";
import { useModalStore } from "./stores/modal";

export default {
  installed: false,
  install(app) {
    if (this.installed) return;

    app.component("LayerModal", LayerModal);

    const store = useModalStore(app.config.globalProperties.$pinia);

    app.config.globalProperties.$openModal = function(opts) {
      let a = app.component(opts.component.id);

      if (typeof a === 'undefined') {
        //delete app._context.components[opts.component.id];
        console.log("Registrando componente", opts.component.name);
        app.component(opts.component.id, opts.component);
      }

      return store.openModal(opts);
    }


    this.installed = true;
  }
};
