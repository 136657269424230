<script>
export default {
    data() {
        return {
            error: {
                status: 200,
            }
        }
    },
    methods: {  
        resetErrors() {
            this.error = {
                status: 200,
            };
        },
        validate() {
            this.resetErrors();
            
            if (Object.keys(this.$refs).length === 0) {
                console.error("No hay 'refs' en el formulario");
                return false;
            }
            //console.log('validate this', this);
            var valid = true;
            for (var ref in this.$refs) {
                console.log('validating ref', ref, this.$refs[ref].validate());
                if (this.$refs[ref].validate() === false) {
                    valid = false;
                }
            }

            if (!valid) {
                //this.$notifications.error("Hay errores en el formulario");
            }

            console.log('validate global', valid);
            return valid;
        }
    },
};
</script>
