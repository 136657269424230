import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    items: [],
  }),
  actions: {
    add(setting) {
      //console.log("Agregando setting", setting);
      this.items.push(setting);
    }
  }
});