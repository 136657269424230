<template>
  <div class="modal">
    <div class="modal-title-wrapper">
      <div class="modal-title">
        <h2>{{ title }}</h2>
      </div>
      <div class="modal-actions" v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalWindow",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
};
</script>