import { useAppsStore } from "./store";
import AppsMenu from "./AppsMenu.vue";
import AppsView from "./views/AppsView.vue";

export { useAppsStore };

export default {
  installed: false,
  install(app, { router, addRouteApps }) {
    if (this.installed) return;

    const store = useAppsStore(app.config.globalProperties.$pinia);

    app.config.globalProperties.$appStore = {
      add: function(application, activate) {
        application.routes.forEach(element => {
            if (element.meta) {
              element.meta.app = app.name;
            } else {
              element.meta = {
                app: app.name
              };
            }
  
            //console.log('Agregando ruta', element);
            router.addRoute(element);
          });
          
          store.addApplication(application);
          
          if (activate) {
            this.activate(application);
          }
      },
      activate: function(application) {
        let activate = false;

        if (typeof router.currentRoute.value.name !== "undefined") {
          application.routes.forEach(function(route) {
            if (route.name === router.currentRoute.value.name) {
              activate = true;
            }
          });
        } else {
          activate = true;
        }

        if (activate === true) {
          store.activate(application);
        }
      }
    };

    app.component('apps-menu', AppsMenu);

    if (addRouteApps) {
      router.addRoute({ path: '/', component: AppsView });
      router.replace(router.currentRoute.value.fullPath)
    }

    router.beforeEach((to, from) => {
      //console.log(to, from);
      if (to.meta.app && to.redirectedFrom === undefined) {
        store.activateByName(to.meta.app);
      }
    })

    this.installed = true;
  }
};
