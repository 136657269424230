<template>
  <div class="listing-card">
    <img @error="imgerr($event)" :src="item.imageUrl"
      v-bind:alt="'Foto de ' + item.title">
    <h3>
      <!--a href="#" @click.prevent="view(item.id)">{{item.title}}</a-->
      <router-link :to="{name:'listing-view', params: {id: item.id}}">{{item.title}}</router-link>
    </h3>

    <div class="dt-info">
      <div>
        <div class="dt">Condición</div>
        <div class="dd">{{ translateCondition(item.condition.value) }}</div>
      </div>
      <div>
        <div class="dt">Precio</div>
        <div class="dd">
          <span v-if="item.currency">
            {{ item.currency.code }} {{ item.price }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useListingStore } from '../stores/listings';

export default {
  name: "ListingItemCard",
  id: "listing-item-card",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    store() {
      return useListingStore(this.$pinia);
    }
  },
  methods: {
    imgerr(e) {
      e.target.src = 'https://img.liberamerkato.com/not-found.400x400.webp';
    },
    translateCondition(k) {
      return this.store.translateCondition(k);
    },
    view(id) {
      this.$openModal({
        component: "",
        props: { listingId: id },
        width: 80
      });
    }
  }
};
</script>
<style>
.listing-card img {
  width: 100%;
}
</style>