<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <input :class="css" :type="type" :value="modelValue" @input="emitValue" v-bind="$attrs" />
    </FormControlWrapper>
</template>

<script>

import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';

export default {
    mixins: [FormControlMixin],
    components: [
        FormControlWrapper
    ],
    props: {
        type: {
            type: String,
            default: "text"
        }
    }
}

</script>