import { defineStore } from 'pinia'

export const useAppsStore = defineStore('apps', {
  state: () => ({
    active: false,
    applications: []
  }),
  actions: {
    change(application) {
      let newApp = this.getApp(application);
      if (newApp) {
        this.active = newApp;
      }
    },
    activateByName(name) {
      if (this.active !== false && this.active.name === name) {
        console.log("appStore: misma app, sale");
        return;
      }

      let app = this.getApplicationByName(name);

      if (app !== false) {
        /*if (!router.currentRoute.meta) {
        }*/

        //console.log("router.currentRoute::", router.currentRoute);
        /*if (router.currentRoute.meta && router.currentRoute.meta.app === name) {
          changeRoute = false;
        }*/

        this.active = app;

        /*if (changeRoute) {
          //console.log("Navegando hacia", newApp.route);
          router.push({ name: newApp.route });
        }*/
      }
    },
    activate(application) {
      this.activateByName(application.name);
    },
    getApplicationByName(name) {
      let newApp = false;

      this.applications.forEach(function(app) {
        if (app.name === name) {
          if (this.active === false || this.active.name !== name) {
            newApp = app;
          }
        }
      }, this);

      return newApp;
    },
    getApp(application) {
      return this.getApplicationByName(application.name);
    },
    addApplication(app) {
      app.routes.forEach(function(route) {
        if (typeof route.meta !== "undefined") {
          route.meta.app = app.name;
        } else {
          route.meta = {
            app: app.name
          };
        }
      });

      this.applications.push(app);
    }
  }
});
