<template>
  <div class="widget-wrapper">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "WidgetWrapper",
};
</script>
