import NewPassModal from "./modals/NewPassModal.vue"
import RegisterVerifyModal from "./modals/RegisterVerifyModal.vue"
import ResetModal from "./modals/ResetModal.vue"
import UserProfile from "./views/UserProfile.vue";
import { useAuthStore } from "./stores/auth";
import { useSettingsStore } from "./stores/settings";

import LoginModal from "./modals/LoginModal.vue";
import RegisterModal from "./modals/RegisterModal.vue";

export default {
  installed: false,
  install(app, { router }) {
    if (this.installed) return;

    const store = useAuthStore(app.config.globalProperties.$pinia);

    const auth = {
      loginModal: function() {
        return app.config.globalProperties.$openModal({
          component: LoginModal,
        });
      },
      registerModal: function() {
        return app.config.globalProperties.$openModal({
          component: RegisterModal
        });
      },
      resetModal: function() {
        return app.config.globalProperties.$openModal({
          component: ResetModal,
        });
      },
      newPassModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: NewPassModal,
          props: props
        });
      },
      verifyModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: RegisterVerifyModal,
          props: props
        });
      },
      isAuthenticated: function() {
        return store.isAuthed;
      },
      userId: function() {
        return store.userId;
      },
      isAuth: function() {
        return this.isAuthenticated();
      },
      isAuthed: function() {
        return this.isAuthenticated();
      }
    };

    router.beforeEach((to, from, next) => {
      if (to.name === "home") {
        if (from.name !== null) {
          //options.store.dispatch("apps/activate", to.meta.app);
        }
      }
    
      if (from.meta.app !== to.meta.app) {
        // Si activo lleva a la ruta "default"
        //console.log(router);
        //options.store.dispatch("apps/change", to.meta.app);
      }
    
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (auth.isAuthenticated()) {
          next();
        } else {
          auth.loginModal()
            .then(function(message) {
              console.log("auth.loginModal:OK", message);
              next();
            })
            .catch(function(message) {
              console.log("auth.loginModal:ERR", message);
              next(false);
            });
        }
      } else {
        next();
      }
    });

    app.config.globalProperties.$http.interceptors.request.use(config => {
      let t = localStorage.getItem("token") || null;

      if (t) {
        config.headers.common["Authorization"] = `Bearer ${t}`;
      }

      return config;
    });

    app.config.globalProperties.$http.interceptors.response.use(response => {
        return response;
      },
      function(error) {
        if (typeof error.response === 'undefined') {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          if (error.config.url === '/login') {
            return Promise.reject(error);
          }
          
          console.log('Rejected 401');
          return new Promise(function(resolve, reject) {
            let instance = app.config.globalProperties.$auth.loginModal();
            let config = error.config;

            instance
              .then(function() {
                let t = localStorage.getItem("token");
                config.headers["Authorization"] = `Bearer ${t}`;
                resolve(options.http.request(config));
              })
              .catch(function(err) {
                reject(err);
              });
          });
        } else {
          return Promise.reject(error);
        }
      }
    );

    app.config.globalProperties.$auth = auth;
    
    this.installed = true;
  }
};

export { UserProfile, useSettingsStore, useAuthStore };