import { defineStore } from 'pinia'

export const useListingStore = defineStore('listing', {
  state: () => ({
    all: [],
    newest: [],
    selected: null,
    auctions: [],
    filters: {
      category: null,
      categories: []
    },
    own: []
  }),
  actions: {
    translateStatus(k) {
      let conditions = {
        'Active': 'Activo',
        'Inactive': 'Inactivo',
        'Paused': 'Pausado'
      }

      return conditions[k];
    },
    translateCondition(k) {
      let conditions = {
        'New': 'Nuevo',
        'Used': 'Usado',
      }

      return conditions[k];
    },
    translateOperation(k) {
      let operations = {
        'Sell': 'Venta',
        'Buy': 'Compra',
        'Auction': 'Subasta'
      }

      return operations[k];
    },
    clearCategory() {
      this.filters.category = null;
    },
    search(data) {
      var params = Object.assign({}, data);

      if (params.p && parseInt(params.p) === 1) {
        delete params.p;
      }

      if (params.cat && parseInt(params.cat) === 0) {
        delete params.cat;
      }

      const self = this;
      this.$http.get(`listings`, { params: params }).then(function(response) {
        self.all = response.data;
        //context.commit("setTotal", { response, index: "all" });
      });
    },
    loadOwn(params) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`listings/own`, { params: params }).then(function (response) {
          self.own = response.data;
          resolve(response.data)
        });
      });
    },
    create(listing) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`listings`, listing).then(function (response) {
          resolve(response.data);
        });
      })
    },
    update(listingId, listing) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.put(`listings/${listingId}`, listing).then(function(response) {
          resolve(response.data);
        });
      })
    },
    upload(listingId, canvas) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const formD = new FormData();
        canvas.toBlob((blob) => {
          formD.append("file", blob);
          self.$http
            .post(`listings/${listingId}/upload`, formD, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function () {
              resolve("OK");
            });
          // Perhaps you should add the setting appropriate file format here
        }, "image/jpeg");
      })
    },
    uploadFormData(listingId, formData) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http
          .post(`listings/${listingId}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            resolve("OK");
          });
      })
    },
    removeImage(listingId, imageId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.delete(`listings/${listingId}/images/${imageId}`).then(function (response) {
          resolve(response.data);
        }).catch(function(err) {
          reject(err);
        });
      })
    },
    loadNewest() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`listings?order=new&limit=5`).then(function(response) {
          self.newest = response.data;
          resolve(response.data);
        });
      });
    },
    getImages(listingId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`listings/${listingId}/images`).then(function(response) {
          resolve(response.data);
        });
      })
    },
    setStatus(newStatus, listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.put(`listings/${listingId}/status`, {status:newStatus})
          .then(function (response) {
            //dispatch('notifications/success', { msg: 'Estado actualizado'}, { root:true })
            resolve(response.data);
          })
          .catch(function(err) {
            reject(err.response.data);
          });
      })
    },
    reloadById(listingId) {
      const self = this;
      this.load(listingId).then(function(data) {
        self.own.forEach(function(item, idx) {
          if (item.id == data.id) {
            self.own[idx] = data;
          }
        })
      })
    },
    load(listingId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`listings/${listingId}`).then(function(response) {
          self.selected = response.data;
          resolve(response.data);
        });
      })
    },
    bid(listingId, price, quantity) {
      const self = this;
      const payload = {
        price: price,
        quantity: quantity
      };
      return new Promise(function (resolve, reject) {
        self.$http.post(`listings/${listingId}/bids`, payload)
          .then(function() {
            resolve("ok");
          })
          .catch(function(err) {
            reject(err.response.data)
          })
      })
    },
    bidInfo(listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`listings/${listingId}/bids/info`)
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function(err) {
            reject(err)
          })
      })
    },
    getSales() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`sales`).then(function (response) {
          resolve(response.data);
        });
      })
    },
    loadAuctions() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`auctions`).then(function (response) {
          self.auctions = response.data;
          self.loadAllBids();
        });
      })
    },
    getBids(listingId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.get(`listings/${listingId}/bids`).then(function (response) {
          resolve(response.data);
        });
      })
    },
    loadAllBids() {
      const self = this;
      this.auctions.forEach(function(auction, idx){
        this.getBids(auction.id).then(function(bids) {
          self.auctions[idx].bids = bids;
        });
      }, this)
    },
    filterByCategory(id) {
      const self = this;
      if (typeof id === 'undefined') {
        self.categories({ parent_id: id });
      } else {
        self.category(id);
        self.categories({ parent_id: id });
      }
    },
    category(categoryId) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`categories/${categoryId}`).then(function(response) {
          self.filters.category = response.data;
          resolve(response.data);
        });
      })
    },
    categories(data) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`categories`, { params: data }).then(function(response) {
          self.filters.categories = response.data;
          resolve(response.data);
        });
      })
    },
    buy(listingId, quantity) {
      const self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post(`listings/${listingId}/buy`, { quantity }).then(function (response) {
          //dispatch("notifications/success", { msg: 'Compra exitosa' }, { root: true });
          //dispatch("notification/unseen", {}, { root: true });
          resolve("ok")
        }).catch(function(err){
          reject(err);
        });
      })
    },
    setDefault(listingId, imageId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`listings/${listingId}/images/${imageId}/default`).then(function (response) {
          resolve(response.data);
        });
      })
    },
    setWinner(listingId, bidId) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$http.post(`listings/${listingId}/winner`, { bidId: bidId }).then(function (response) {
          resolve(response.data);
        });
      })
    },
  }
});

/*

    removeFilterCategory({ commit }) {
      commit("setFilterCategory", null);
    },
    questionReply(context, data) {
      return new Promise(function(resolve, reject) {
        console.log('store.category');
        http.post(`listings/${data.listing_id}/questions/${data.question_id}/replies`, data.data).then(function(response) {
          resolve(response.data);
        });
      })
    },
    edit({ commit }, data) {
      return new Promise(function(resolve, reject) {
        http.get(`listings/${data.id}/edit`).then(function(response) {
          commit("setEditable", response.data);
          resolve(response.data);
        });
      })
    },
    updateCategory({ commit }, data) {
      return new Promise(function(resolve, reject) {
        var fields = { category_id: data.category_id };
        http.put(`listings/${data.listing_id}`, fields).then(function(response) {
          resolve(response);
        });
      })
    },
    address({ commit, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        var fields = { address_id: data.address_id };
        http.put(`listings/${data.listing_id}`, fields).then(function(response) {
          dispatch('notifications/success', { msg: "Direccion actualizada" }, { root: true });
          resolve(response);
        });
      })
    },
    purchases({ commit }, data) {
      return new Promise(function (resolve, reject) {
        http.get(`purchases`, data).then(function (response) {
          resolve(response.data);
        });
      })
    },
  }
};
*/
