<template>
    <FormControlWrapper :label="label" :errors="errors" :apiErrorsProp="apiErrors">
        <span>
            <password-visibility input="login-password"></password-visibility>
        </span>
        <input type="password" ref="control" id="login-password" :value="modelValue" @input="emitValue" :class="css"
            v-bind="$attrs" placeholder="Contraseña" />
    </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormControlMixin from './FormControlMixin.vue';
import PasswordVisibility from './PasswordVisibility.vue';

var constraints = {
    lo: "(?=.*[a-z])",
    up: "(?=.*[A-Z])",
    nu: "(?=.*[0-9])",
    si: "(?=.*[!@#\$%\^&\*])",
    le: "(?=.{8,})",
};

var msgs = {
    lo: "Falta una letra minúscula",
    up: "Falta una letra mayúscula",
    nu: "Falta un número",
    si: "Falta un símbolo",
    le: "La contraseña es muy corta",
};

export default {
    mixins: [FormControlMixin],
    name: "FormPasswordWrapper",
    methods: {
        _validate() {
            return this.validatePassword(this.modelValue);
        },
        _validateValue(value) {
            return this.validatePassword(value);
        },
        validatePassword(value) {
            //console.log('validatePassword', value);   
            if (this.isLogin) {
                var reg = new RegExp(`^${constraints.le}`);
                if (!reg.test(value)) {
                    this.errors.push(msgs.le);
                }
            } else {
                for (var c in constraints) {
                    var exp = constraints[c];
                    var reg = new RegExp(`^${exp}`);
                    if (!reg.test(value)) {
                        this.errors.push(msgs[c]);
                    }
                }
            }

            if (this.compare) {
                const ref = this.findRefByName(this.compare);
                if (ref) {
                    if (ref.modelValue != value) {
                        this.errors.push('Las contraseñas no coinciden');
                    } else {
                        ref.errors.forEach(function(val, idx) {
                            if (val === 'Las contraseñas no coinciden') {
                                ref.errors.splice(idx, 1)
                            }
                        });
                    }
                }
            }

            return (this.errors.length === 0);
        }
    },
    props: {
        isLogin: {
            type: Boolean,
            default: false,
        },
        compare: {
            type: String,
            default: "",
        }
    },
    components: { FormControlWrapper, PasswordVisibility }
};
</script>