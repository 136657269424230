import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import FormWrapperPlugin from '@linkedcode/form-wrapper'
import AxiosPlugin from '@linkedcode/axios-plugin'
import AuthPlugin, { useSettingsStore } from '@linkedcode/auth'
import NavbarMenu from '@linkedcode/navbar-menu'
import AppStore from '@linkedcode/app-store-plugin'
import LimeApp from '@linkedcode/lime-app'
import SystemArea from '@linkedcode/system-area'

import AddressPlugin from '@linkedcode/address-plugin'
import Notifications from '@linkedcode/notifications'

import LayerModal from '@linkedcode/layer-modal'

import CartApplet from '@linkedcode/lime-app/src/components/CartApplet.vue'
import ChatApplet from '@linkedcode/lime-app/src/components/ChatApplet.vue'
import UserApplet from '@linkedcode/auth/src/component/UserApplet.vue'
import NotificationApplet from '@linkedcode/auth/src/component/NotificationApplet.vue'
import config from './config/config'

import DesignPlugin from '@linkedcode/design'

import '@linkedcode/styles/src/index.scss'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)

app.use(AxiosPlugin, {
  api: { url: config.API_URL },
  pinia
})

app.use(NavbarMenu)
app.use(AppStore, { router })
app.use(SystemArea)

app.use(AuthPlugin, { router })

app.use(LayerModal)

const settingsStore = useSettingsStore()
app.use(AddressPlugin, { settingsStore: settingsStore })
app.use(Notifications)
app.use(FormWrapperPlugin)
app.use(DesignPlugin)

app.config.globalProperties.$appStore.add(LimeApp)
app.config.globalProperties.$config = config

// El router debe estar en el medio para con add() cargar las rutas y
// en activate poder usar $route

app.config.globalProperties.$systemArea.add(ChatApplet)
app.config.globalProperties.$systemArea.add(NotificationApplet)
app.config.globalProperties.$systemArea.add(CartApplet)
app.config.globalProperties.$systemArea.add(UserApplet)

app.config.globalProperties.$appStore.activate(LimeApp)

app.use(router)

app.mount('#app')
