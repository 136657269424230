<template>
    <li class="dropdown-button-action">
        <button type="button" v-bind="$attrs"><slot></slot></button>
    </li>
</template>
<script>
export default {
    name: "DropdownAction",
    inheritAttrs: false
}
</script>