<template>
  <div class="section-wrapper">
    <div class="section-title-wrapper">
      <div class="section-title">
        <h2>{{ title }}</h2>
      </div>
      <div class="section-title-actions" v-if="this.$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="section-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionWrapper",
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>