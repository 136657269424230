<template>
  <ModalWindow title="Mis direcciones">
    <template #actions>
      <button type="button" @click="addAddress">Agregar dirección</button>
    </template>
    <GridList>
      <GridListItem v-for="a in addresses" v-bind:key="a.id">
        <div>
          <div class="dt">Titulo</div>
          <div>{{ a.title }}</div>
        </div>
        <DefinitionItem title="Dirección">
          <div>{{ line1(a) }}</div>
          <div>{{ line2(a) }}</div>
        </DefinitionItem>
        <div>
          <button type="button" class="primary" @click="edit(a)">Editar</button>
          <!--button type="button" class="btn btn-primary" v-on:click="remove(a.id)"><i class="bi bi-trash"></i></button-->
        </div>
      </GridListItem>
    </GridList>
  </ModalWindow>
</template>

<script>

class Address {
  constructor(obj) {
    this.o = obj;
    console.log(obj);
  }
  toDTO() {
    return {
      title: this.o.title || "",
      description: this.o.description || "",
      street: this.o.street || "",
      number: this.o.number,
      floor: this.o.floor,
      department: this.o.department,
      country_id: this.o.country.id,
      province_id: this.o.province.id,
      city_id: this.o.city.id
    }
  }
}

import AddressAddEditForm from "../components/AddressAddEditForm.vue";
import { useAddressStore } from "../store";

export default {
  name: "MyAddresses",
  id: "my-addresses",
  components: {
    AddressAddEditForm
  },
  data() {
    return {
    }
  },
  computed: {
    store() {
      return useAddressStore(this.$pinia);
    },
    addresses() {
      return this.store.own;
    }
  },
  methods: {
    addAddress() {
      var self = this;
      this.$openModal({
        component: AddressAddEditForm
      }).then(function () {
        self.load();
      }).catch(function (b) {
        console.log(b);
      });
    },
    format(address) {
      return this.$address.format(address);
    },
    line1(address) {
      return this.$address.line1(address);
    },
    line2(address) {
      return this.$address.line2(address);
    },
    edit(address) {
      const self = this;
      console.log('edit', address);
      this.$openModal({
        component: AddressAddEditForm,
        width: 50,
        props: { address: address }
      }).then(function (a) {
        self.store.loadById(address.id);
        console.log(a);
      }).catch(function (b) {
        console.log(b);
      });;
    },
    remove(id) {
      var self = this;
      this.$store.dispatch('address/remove', { address_id: id }).then(function () {
        //this.load();
      });
    },
    load() {
      this.store.load();
    }
  },
  mounted() {
    this.store.load();
  }
}
</script>