<template>
  <ModalWindow title="Configuración" id="settings">
    <GridList>
      <GridListItem>
        <i class="bi bi-person big-icon"></i>
        <div>
          <a href="#" @click.prevent="profile">Perfil</a>
          <div>Nombre de usuario, redes sociales</div>
        </div>
      </GridListItem>
      <GridListItem v-for="setting in settings" :key="setting.name">
        <i :class="setting.icon"></i>
        <div class="vstack">
          <a href="#" @click="openModal(setting.component)">{{ setting.name }}</a>
          <div><small>{{ setting.description }}</small></div>
        </div>
      </GridListItem>
    </GridList>
  </ModalWindow>
</template>

<script>

import UserProfile from '../views/UserProfile.vue';
//import MyAddresses from '@linkedcode/address-plugin/src/views/MyAddresses.vue';
import { useSettingsStore } from '../stores/settings';

export default {
  id: 'settings',
  name: 'Settings',
  computed: {
    settings() {
      const store = useSettingsStore();
      return store.items;
    }
  },
  methods: {
    profile() {
      this.$openModal({
        component: UserProfile,
        cssClass: ['open-on-right']
      }).then(function(a) {
        console.log(a);
      });
    },
    openModal(c) {
      this.$openModal({
        component: c
      }).then(function(a) {
        console.log(a);
      }).catch(function(res) {
        console.log(res);
      });
    },
    /*addresses() {
      this.$openModal({
        component: MyAddresses,
        cssClass: ['open-on-right']
      }).then(function(a) {
        console.log(a);
      });
    }*/
  }
}
</script>